<template>
  <main>
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">{{ $t("customer_service") }}</li>
        </ol>
      </div>
    </nav>
    <div class="page-content">
      <div class="container bg-white p-3 p-md-5 br-1">
        <div class="row">
          <div class="col-lg-12">
            <h4 class="text-primary">{{ $t("customer_service") }}</h4>
            <h6 class="mt-3 mb-0">
              <font-awesome-icon
                class="text-primary"
                icon="fa-solid fa-phone"
              />
              +31 (0) 495 752039
            </h6>
            <small
              >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
              ratione, quam voluptatem sit debitis repellat.</small
            >
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
          <div class="col-lg-2 col-sm-4 col-6 mb-2 mb-md-4">
            <h6 class="text-primary mb-0">Lorem, ipsum.</h6>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
            <p class="text-dark"><u>Lorem, ipsum.</u></p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>
